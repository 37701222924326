export const mixins = {
  methods: {
    compareValues (key, order = 'asc') {
      return (a, b) => {
        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key]
        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    },
    sortFnc (col, data) {
      if (col.sortable === false) return
      let sortIcon = ''

      if (!this.sortBy) {
        this.sortBy = col.value
        sortIcon = 'mdi-arrow-up-bold'
      } else if (this.sortBy === col.value) {
        if (col.sortIcon === 'mdi-arrow-up-bold') {
          sortIcon = 'mdi-arrow-down-bold'
        } else {
          sortIcon = 'mdi-arrow-up-bold'
        }
      } else {
        const indexPrevious = this.headers.findIndex(c => c.value === this.sortBy)
        this.$set(this.headers, indexPrevious, {
          ...this.headers[indexPrevious],
          sortIcon: sortIcon
        })
        this.sortBy = col.value
        sortIcon = 'mdi-arrow-up-bold'
      }

      const index = this.headers.findIndex(c => c.value === col.value)
      this.$set(this.headers, index, {
        ...this.headers[index],
        sortIcon: sortIcon
      })

      const compareValuesFn = col.compareValues ? col.compareValues : this.compareValues
      if (sortIcon === 'mdi-arrow-up-bold') {
        data.sort(compareValuesFn(col.value, 'desc'))
      } else {
        data.sort(compareValuesFn(col.value))
      }
    }
  }
}
