<template >
  <v-main class="pt-0 pl-2 pr-2" light>
    <v-row>
      <v-col xl="7" lg="7" md="12" sm="12" sx="12">
        <v-sheet rounded="lg">
          <v-row class="pl-3 pr-3">
            <v-card-title>{{ $t("Booking.BookingList") }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="openBookingCalendar"
              class="mt-3 mr-3 pa-2 float-right"
              color="primary"
            >
              <v-icon dark class="mr-2"> mdi-calendar-month-outline </v-icon>
              {{ $t("Calendar.Calendar") }}
            </v-btn>
            <v-btn
              :disabled="bookingWindowID == 1"
              @click="handleClickCreateNewBooking"
              class="mt-3 mr-3 pa-2"
              style="float: right"
              color="primary"
            >
              <v-icon dark class="mr-2"> mdi-plus </v-icon>
              {{ $t("Booking.CreateNewBooking") }}
            </v-btn>
          </v-row>

          <!-- search -->
          <SearchBooking
            :options="searchOptions"
            @onChangeSearchOptions="onChangeSearchOptions"
          />

          <!-- list booking table -->
          <v-skeleton-loader
            :loading="skeletonLoadingTable"
            height="500"
            type="table"
          >
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="tableOpts"
              :loading="tableLoading"
              :server-items-length="totals"
              :footer-props="{
                tableFooterProps,
                'items-per-page-text': $t('Common.RowsPerPage'),
              }"
              @update:items-per-page="renderBookingList"
              @update:page="onChangePagination"
              hide-default-header
              disable-sort
              class="app-custom-table bookingData__table"
              mobile-breakpoint="0"
              :item-class="colorSelectedRow"
            >
              <!-- 
              @pagination="renderBookingList" -->
              <!-- sortable -->
              <template v-slot:header="{ props }">
                <thead class="v-data-table-header">
                  <tr>
                    <th
                      v-for="head in props.headers"
                      :key="head.text"
                      @click="sortFnc(head, items)"
                      :class="{
                        'cursor-pointer': head.sortable !== false,
                        'text-left': head.align === 'left',
                        'text-center': head.align === 'center',
                        'text-right': head.align === 'right',
                      }"
                    >
                      {{ head.text }}
                      <v-icon v-if="!head.sortable" class="icon-sortable">
                        {{ head.sortIcon }}
                      </v-icon>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:[`item.fullName`]="{ item }">
                <v-avatar size="26px" color="primary" class="avatart__patient">
                  <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
                  <span v-else class="white--text">
                    {{ getNameInnitial(item.fullName) }}
                  </span>
                </v-avatar>
                <a
                  class="ml-2 name__patient"
                  @click="handleClickBookingItem(item)"
                  >{{ item.fullName }}</a
                >
              </template>
              <template v-slot:[`item.genderType`]="{ item }">
                <GenderItem :genderType="item.genderType" />
              </template>
              <template v-slot:[`item.timeStart`]="{ item }">
                <div class="d-flex flex-column w-100">
                  <div class="date__visit">
                    {{ printDateHourVisit(item.timeStart) }}
                  </div>
                  <div class="time__pending">
                    {{ printDateHourVisit(item.timeStart, false) }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="confirmDelete(item.bookingID)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                {{ $t("EmptyData.Booking") }}
              </template>
            </v-data-table>
          </v-skeleton-loader>
          <!-- end list booking table -->
        </v-sheet>

        <PatientLastVisits ref="PatientLastVisits" />
      </v-col>
      <v-col xl="5" lg="5" md="12" sm="12" sx="12">
        <v-sheet rounded="lg">
          <v-window v-model="bookingWindowID" reverse>
            <v-window-item :key="0" class="pb-8 w-100">
              <NoDataFound
                ref="NoDataFound"
                :message="'Select a booking on the left to view!'"
                :default="true"
              >
                <v-card-title>
                  {{ $t("Patient.PatientInformation") }}
                </v-card-title>
                <PatientInformation :patientID="patientID" />
                <v-divider></v-divider>
                <v-card-title>
                  {{ $t("Booking.BookingInformation") }}
                </v-card-title>
                <BookingInformation :bookingID="bookingID" />
                <v-row class="pl-3 pr-3" justify="center">
                  <v-btn @click="confirmDelete(bookingID)" width="150px" text>
                    {{ $t("Button.Cancel") }}
                  </v-btn>
                  <v-btn
                    @click="handleClickCheckIn"
                    width="150px"
                    color="primary"
                  >
                    Check In
                  </v-btn>
                </v-row>
              </NoDataFound>
            </v-window-item>

            <v-window-item :key="1" class="pa-0 ma-0 pb-6">
              <v-card-title>
                {{ $t("Booking.CreateNewBooking") }}
              </v-card-title>
              <CreateBookingForm
                ref="CreateBookingForm"
                @onCancelCreate="bookingWindowID = 0"
                @onCreateSuccess="handleCreateBookingSuccess"
              />
            </v-window-item>

            <v-overlay :value="overlayForm" :absolute="true" :opacity="0.2">
              <v-progress-circular indeterminate color="primary" :size="50">
              </v-progress-circular>
            </v-overlay>
          </v-window>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="420px">
      <v-card>
        <v-card-title class="headline">{{
          $t("DialogConfirm.DeleteBooking")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("Button.Cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteSelectedBooking">{{
            $t("Button.Accept")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateAdmissionForm
      ref="admissionCard"
      @onCancelAdmission="dialogAdmission = false"
      @onAdmissionSuccess="handleAdmissionSuccess"
    />
    <DialogConfirm
      ref="confirmCreateNewBooking"
      :message="$t('DialogConfirm.CreateBooking')"
    />
    <DialogConfirm
      ref="confirmPatientAdmission"
      :dialogWidth="800"
      :message="admissionMessage"
    />
  </v-main>
</template>

<script>
import { mixins } from "../../mixins/mixins";

import BookingInformation from "../../components/commonComponents/OnlineBooking/BookingInformation";
import PatientInformation from "@/components/commonComponents/PatientInfo/PatientInformation";
import PatientLastVisits from "@/components/commonComponents/PatientInfo/PatientLastVisits";
import CreateAdmissionForm from "../../components/commonComponents/Admission/CreateAdmissionForm";

import SearchBooking from "../../components/commonComponents/OnlineBooking/SearchBooking";
import CreateBookingForm from "../../components/commonComponents/OnlineBooking/CreateBookingForm";
import GenderItem from "../../components/commonComponents/Queue/components/GenderItem";

import NoDataFound from "../../components/NoDataFound";
import DialogConfirm from "../../components/DialogConfirm";

import BookingService from "../../services/booking";
import moment from "moment";
import { getNameInnitial } from "../../plugins/helper";
import {
  BookingType,
  TABLE_OPTS,
  TABLE_ROWS_PER_PAGE,
} from "../../plugins/constant";

export default {
  components: {
    BookingInformation,
    SearchBooking,
    PatientInformation,
    CreateBookingForm,
    CreateAdmissionForm,
    GenderItem,
    NoDataFound,
    DialogConfirm,
    PatientLastVisits,
  },
  data() {
    return {
      // placeholder lazy loading
      skeletonLoadingTable: true,

      // current
      bookingWindowID: 0,

      bookingID: null,
      patientID: null,
      serviceID: null,
      selectedID: -1, // keeps track of which row to highlight
      dialogDelete: false,

      status: {
        type: "error",
        show: false,
        message: "",
      },

      searchOptions: {
        keySearch: "",
        clinicID: "",
        dates: [
          moment().startOf("day").format(),
          moment().endOf("day").format(),
        ],
      },

      // booking list table
      headers: [
        { text: this.$t("Clinic.Clinic"), value: "clinicName" },
        // { text: "Booking Code", value: "codeBooking", align: "left" },
        { text: this.$t("Patient.PatientName"), value: "fullName" },
        {
          text: this.$t("Patient.Gender"),
          value: "genderType",
          sortable: false,
        },
        { text: this.$t("Patient.DOB"), value: "dob", align: "center" },
        { text: this.$t("Patient.Phone"), value: "phone", align: "center" },
        {
          text: this.$t("Booking.BookingTime"),
          value: "timeStart",
          align: "center",
        },
        { text: "", value: "actions", sortable: false },
      ],
      totals: 0,
      items: [],
      tableLoading: true,
      tableOpts: TABLE_OPTS,
      tableFooterProps: {
        itemsPerPageOptions: TABLE_ROWS_PER_PAGE,
      },
      sortBy: "",
      // not using overlay at the moment
      overlayForm: false,
      admissionMessage: "",
    };
  },
  async mounted() {
    await this.renderBookingList();
    this.skeletonLoadingTable = false;
    console.log(this);
  },
  mixins: [mixins],
  methods: {
    getNameInnitial,
    showError(message) {
      this.$toast.error(message);
    },
    confirmDelete(bookingID) {
      this.bookingID = bookingID;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    handleClickCreateNewBooking() {
      this.bookingWindowID = 1;
      const timeout = setTimeout(() => {
        this.$refs.CreateBookingForm.cleanForm();
        clearTimeout(timeout);
      }, 200);
    },
    handleCreateBookingSuccess() {
      this.bookingWindowID = 0;
      // clean search options
      this.searchOptions.keySearch = "";
      // re-render list bookings
      this.renderBookingList();
    },
    async handleAdmissionSuccess(result, episodeRecordID) {
      var serviceNames =
        "<ul><li>" +
        result.orderItems.map((i) => i.itemName).join("</li><li>") +
        "</li></ul>";
      this.admissionMessage = `${this.$t("DialogConfirm.PatientAdmission1")} '${
        result.admission.patientName
      }' ${this.$t("DialogConfirm.PatientAdmission2")} ${serviceNames}`;
      if (!(await this.$refs.confirmPatientAdmission.confirm())) return;
      this.$toast.success(`${this.$t("Toast.SuccessAdmitPatient")}`);
      this.dialogAdmission = false;
      // clean search options
      this.searchOptions.keySearch = "";
      this.renderBookingList();
      this.$refs.PatientLastVisits.hide();
      this.$router.push({ path: `/queue/overviewid/${episodeRecordID}` });
    },
    async handleClickBookingItem(item) {
      this.selectedID = item.bookingID;
      // not using overlay at the moment
      this.overlayForm = false;
      this.bookingWindowID = 0;
      this.bookingID = item.bookingID;
      this.patientID = item.patientID;
      this.serviceID = item.serviceID;
      this.isLoadFromUrl = false;
      this.overlayForm = false;
      // hide nodata component
      this.$refs.NoDataFound.hide();
      // show last visit components
      this.$refs.PatientLastVisits.renderLastVisits(
        item.patientID,
        item.fullName
      );
    },
    colorSelectedRow(item) {
      return item.bookingID == this.selectedID ? "grey lighten-2" : "";
    },
    handleClickCheckIn() {
      this.$refs.admissionCard.renderAdmissionDetail(
        this.bookingID,
        this.patientID,
        this.serviceID
      );
    },
    onChangeSearchOptions(searchOptions) {
      if (searchOptions) {
        this.searchOptions = searchOptions;
      }
      this.renderBookingList();
    },
    async deleteSelectedBooking() {
      var result = await BookingService.deleteBooking(this.bookingID);
      if (result.error) {
        this.showError("Cannot delete bookings. Please try again later!");
        return;
      }
      this.closeDelete();
      await this.renderBookingList();
    },
    async renderBookingList() {
      this.tableLoading = true;

      const { keySearch, dates, clinicID } = this.searchOptions;
      const { page, itemsPerPage } = this.tableOpts;

      var timeStart = (dates && dates[0]) || "";
      var timeEnd = (dates && dates[1]) || "";
      if (timeStart) {
        timeStart = moment(timeStart)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      if (timeEnd) {
        timeEnd = moment(timeEnd).endOf("day").format("YYYY-MM-DD HH:mm:ss");
      }
      var result = await BookingService.searchBookings(
        keySearch,
        timeStart,
        timeEnd,
        clinicID,
        BookingType.Waiting,
        page,
        itemsPerPage
      );
      this.tableLoading = false;

      if (result.error) {
        this.showError("Cannot get list bookings. Please try again later!");
        return;
      }

      this.items = result.items.map((item) => {
        item.phone = item.phone || "N/A";
        item.email = item.email || "N/A";
        item.fullName = item.fullName || "N/A";
        item.dob = moment(item.dob).format("DD/MM/YYYY"); // make DOB format follow beginning of timeStart format
        return item;
      });

      this.totals = result.totals;

      // if has data
      if (result.items.length) {
        // if has data => default load 1st booking item
        // this.$refs.NoDataFound.hide();
        // await this.handleClickBookingItem(result.items[0]);
        // NEW FEATURE: if booking items found => display message click to view
        this.$refs.NoDataFound.show("Select a booking on the left to view!");
      }
      // if has no data
      else {
        this.$refs.NoDataFound.show("Oops! No booking can be found.");
        // if keysearch is present
        if (this.searchOptions.keySearch) {
          // confirm if user want to create new booking
          if (await this.$refs.confirmCreateNewBooking.confirm()) {
            this.handleClickCreateNewBooking();
          }
          // if user cancel popup
          else {
            // clean keysearch
            this.searchOptions.keySearch = "";
            // re-render list bookings
            this.renderBookingList();
          }
        }
      }
    },
    async onChangePagination(value) {
      this.tableOpts.page = value;
      await this.renderBookingList();
    },
    openBookingCalendar() {
      this.$router.push({ path: "/booking-calendar" });
    },
    printDateHourVisit(time, date = true) {
      if (date) {
        return moment(time).format("DD/MM/YYYY");
      }
      let start = moment(time).format("HH:mm");
      let end = moment(time).add("1", "hours").format("HH:mm");
      return `${start}-${end}`;
    },
  },
};
</script>

<style scoped>
.date__visit,
.time__pending {
  font-size: 14px;
  width: 100%;
}
.time__pending {
  font-weight: 600;
}
.bookingData__table {
  overflow-x: auto;
}
.row {
  margin: 0 !important;
}

@media screen and (max-width: 1100px) {
  .avatart__patient {
    display: none;
  }
  .name__patient {
    font-size: 14px;
  }
}
</style>
