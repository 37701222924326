<template>
  <v-row class="pl-3 pr-3">
    <v-col cols="12" md="4">
      <v-select
        v-model="options.clinicID"
        :items="clinics"
        append-icon="mdi-home-outline"
        :label="$t('Clinic.Clinic')"
        placeholder="My clinic"
        outlined
        dense
        @change="onChangeSearchOptions"
      ></v-select>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="options.keySearch"
        append-icon="mdi-magnify"
        :label="$t('Search.EnterToSearch') + '...'"
        outlined
        dense
        v-on:keyup.enter="onChangeSearchOptions"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="options.dates"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            :label="$t('Time.TimeRange')"
            append-icon="mdi-calendar"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="options.dates"
          no-title
          scrollable
          range
          @change="onChangeSearchOptions"
        >
          <v-btn
            text
            @click="
              options.dates = [];
              $refs.menu.save(options.dates);
              onChangeSearchOptions();
            "
          >
            Search all
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn text color="primary" @click="menu = false"> Cancel </v-btn> -->
          <v-btn text color="primary" @click="$refs.menu.save(options.dates)">
            Apply
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
const DateFromat = "DD-MM-YYYY";
import BrandService from "@/services/brand";

export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        keySearch: "",
        clinicID: "",
        dates: [],
      }),
    },
  },
  data() {
    return {
      menu: false,
      clinics: [],
    };
  },
  computed: {
    dateRangeText() {
      if (!this.options.dates.length) return "All";
      const fromDate = moment(this.options.dates[0]).format(DateFromat);
      const toDate = moment(this.options.dates[1]).format(DateFromat);
      const toDay = moment().format(DateFromat);
      if (toDay == fromDate && toDay == toDate) return `Today, ${toDay}`;
      if (fromDate == toDate) return fromDate;
      return `${fromDate} to ${toDate}`;
    },
  },
  created() {
    this.renderListClinicOptions();
  },
  methods: {
    onChangeSearchOptions() {
      var value = JSON.parse(JSON.stringify(this.options));
      if (value.dates[0] && !value.dates[1]) return;
      this.$emit("onChangeSearchOptions", value);
    },
    async renderListClinicOptions() {
      this.clinics = [];
      var result = await BrandService.searchBrandClinics(1, 100);
      this.clinics = result.items.map((i) => ({
        text: i.clinicName,
        value: i.clinicID,
      }));
      this.clinics.unshift({
        text: this.$t('Clinic.AllClinics'),
        value: "",
      });
    },
  },
};
</script>