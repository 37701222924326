<template>
  <v-row class="pl-4 pr-4">
    <v-col cols="12" md="12" class="pl-10">
      <v-skeleton-loader
        :loading="isLoading"
        type="list-item-three-line, list-item-two-line, list-item-three-line"
      >
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon>{{ $t('Service.ServiceName') }}:
          <b class="ml-1">{{ booking.serviceName }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon>{{ $t('Booking.BookingCode') }}:
          <b class="ml-1">{{ booking.codeBooking }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Booking.BookingTime') }}:
          <b class="ml-1">{{ booking.timeStart }} </b>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Clinic.ClinicName') }}:
          <b class="ml-1">{{ booking.clinicName }} </b>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Admission.AdmissionType') }}:
          <b class="ml-1">{{ booking.bookingTypeName }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Common.ChiefComplains') }}:
          <b class="ml-1">{{ booking.chiefCompain }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Common.Allergies') }}:
          <span class="ml-1" v-html="booking.alleryStr"></span>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Common.ChronicDiseases') }}:
          <span class="ml-1" v-html="booking.chronicDiseaseStr"></span>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Common.PersonalBehaviour') }}:
          <span class="ml-1" v-html="booking.personalBehaviourStr"></span>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Common.PastHistory') }}:
          <span class="ml-1" v-html="booking.pastHistoryStr"></span>
        </p>
        <p class="ma-0 mb-2 text-space">
          <v-icon>mdi-arrow-right</v-icon> {{ $t('Common.FamilyHistory') }}:
          <span class="ml-1" v-html="booking.familyHistoryStr"></span>
        </p>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import BookingService from "../../../services/booking";
import { BookingAdditionalStatType } from "../../../plugins/constant";

export default {
  props: {
    bookingID: {
      type: Number,
      default: null,
    },
  },
  watch: {
    bookingID: {
      handler(val) {
        this.renderBookingDetail(val);
      },
    },
  },
  data: () => ({
    booking: {},
    isLoading: true,
  }),
  created() {},
  methods: {
    async renderBookingDetail(bookingID) {
      if (!bookingID) {
        return;
      }
      this.isLoading = true;
      var result = await BookingService.getBookingDetail(bookingID);
      this.isLoading = false;
      if (result.error) {
        this.showError("Can not get clinic detail. Please try again later.");
        return;
      }
      // transform result before mapping data
      result.timeStartStr = moment(result.timeStart).format(
        "DD/MM/YYYY, HH:mm"
      );
      if (result.timeEnd == result.timeStart) {
        result.timeEndStr = moment(result.timeStart)
          .add(1, "hours")
          .format("HH:mm");
      } else {
        result.timeEndStr = moment(result.timeEnd).format("HH:mm");
      }
      result.alleryStr = result.additionalInfos
        .filter((i) => i.targetStatType == BookingAdditionalStatType.Allergy)
        .map((i) => i.targetStatName)
        .join(", ");
      result.chronicDiseaseStr = result.additionalInfos
        .filter(
          (i) => i.targetStatType == BookingAdditionalStatType.ChronicDisease
        )
        .map((i) => i.targetStatName)
        .join(", ");
      result.personalBehaviourStr = result.additionalInfos
        .filter(
          (i) => i.targetStatType == BookingAdditionalStatType.PersonalBehaviour
        )
        .map((i) => i.targetStatName)
        .join(", ");
      result.pastHistoryStr = result.additionalInfos
        .filter(
          (i) => i.targetStatType == BookingAdditionalStatType.PastHistory
        )
        .map((i) => i.targetStatName)
        .join(", ");
      result.familyHistoryStr = result.additionalInfos
        .filter(
          (i) => i.targetStatType == BookingAdditionalStatType.FamilyHistory
        )
        .map((i) => i.targetStatName)
        .join(", ");
      // end of transform result
      this.booking = result;
      this.booking.timeStart = `${result.timeStartStr} - ${result.timeEndStr}`;
      // Display NA if not existed
      for (var key in this.booking) {
        this.booking[key] = this.booking[key] || "N/A";
      }
    },
  },
};
</script>

<style scoped>
.v-icon {
  margin-right: 10px;
}
</style>